import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import {useStaticQuery, graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import {convertToBgImage} from 'gbimage-bridge';
import BackGlossary from '../../../../components/BackGlossary';
import BannerRussel from '../../../../components/BannerRussel';

const GlossaryPageContent = () => {
  return (
    <div className="md:px-5 max-w-[1285px]">
      <div className="mb-8 text-xl font-bold text-left md:text-2xl">Identity Attack Surface</div>
      <p className="mb-12 text-lg text-left">
        An attack surface refers to the entirety of potential entry points through which unauthorized access to a system
        can be attempted. It encompasses all vulnerabilities, weaknesses, and endpoints that could potentially be
        exploited by malicious actors to launch security attacks.<br></br>
        <br></br> A system's identity attack surface is a crucial aspect of its overall security posture. The larger
        system identity sprawl, the higher the risk of potential security breaches. As technology and systems evolve,
        the identity attack surface may expand due to the introduction of new software, network connections, or
        integration with third-party services. <br></br>Consequently, the task of effectively managing and reducing the
        Identity attack surface becomes paramount to ensuring robust cybersecurity.
      </p>
    </div>
  );
};

const PbacPolicyBasedAccessControl = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderImage} = dataImages;
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const title = `Identity Attack Surface`;
  const description = `Explore our site to discover detailed information about ${title}`;
  return (
    <Layout seo={<Seo title={`Glossary: ${title} | Trustle`} description={description} />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="">
          <div className="flex flex-col pt-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
            <SectionIntro isPageHeader hero="Glossary" title={title} description={description} />
          </div>
        </section>

        <section className="pb-8">
          <div className="flex flex-col py-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto">
            <div className=" max-w-[1360px] 3xl:max-w-[1420px] mx-auto text-blue">
              <div className="py-16">
                <BackGlossary />
              </div>
              <GlossaryPageContent />
            </div>
          </div>
        </section>
      </BackgroundImage>
      <section className="pb-16">
        <BannerRussel
          title="Curious how Trustle works?"
          description={'Get an inside look at Trustle. Schedule a demo with us today.'}
        />
      </section>
    </Layout>
  );
};
export default PbacPolicyBasedAccessControl;
